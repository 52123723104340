header {
    height: 100vh;
    padding-top: 2rem;
    display: flex;
    border-radius: 1.5rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 3rem;
}

.name_container {
    display: flex;
    flex-direction: column;
    text-align: center;
    background: var(--container-bg);
    justify-content: center;
    align-items: center;
    width: 50%;
}

.image_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    background: rgba(0, 0, 0, 0.377);
    justify-content: center;
}
  

.name_highlight {
    color: var(--color-primary);
}

h3 {
    font-size: 1.8rem;
}

p {
    width: 50%;
}


img {
    padding: 0 10%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}


.quote {
    
    width: 100%;
    margin: 0;
    padding: 1.1rem;
    background: rgba(0, 0, 0, 0.377);
    text-align: center;
}


.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
    
}

.header_icon {
    text-align: center;
    background: none;
    vertical-align: middle; 
    font-size: 1.5rem;
}
/* Socials */
.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
    padding: .5rem;
    background: rgba( 21, 21, 51, 0.65 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 3px );
    -webkit-backdrop-filter: blur( 3px );
    border-radius: 1.5rem;
    border: 1px solid transparent;
}

.header__socials::after {
    content: "";
    width: 2px;
    height: 2rem;
    background: var(--color-primary);
    
}

/* scroll down */
.scroll__down {
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: .9rem;
    padding: .5rem;
    background: rgba( 21, 21, 51, 0.65 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 3px );
    -webkit-backdrop-filter: blur( 3px );
    border-radius: 1.5rem;
    border: 1px solid transparent;
}
