/* font import */
@import url('https://fonts.googleapis.com/css2?family=Hind+Madurai:wght@400;600;700&family=Kreon:wght@300;400;600;700&family=Text+Me+One&display=swap');
 

*{
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
     
}

:root {
    /*Variable Colors */
    --color-bg: #0a0a0a;
    --color-bg-variant: #000000;
    --container-bg: #1a1625;
    --color-primary: #4db5ff;
    --color-primary-variant: rgba(77, 181, 255, .04);
    --color-white: #fff;
    --color-light: #a4c3da;
    --color-tertiary: #320f74a6;
    /*Container Variables */
    --container-width-lg: 75%;
    --contanier-width-md: 86%;
    --container-width-sm: 90%;
    /*Variable Fonts */
    --primary-font:'Kreon', serif;
    --secondary-font:'Text Me One', sans-serif;
    --tertiary-font:'Hind Madurai', sans-serif;
    /*Transition Variables */
    --transition: all 400ms ease;

}

html {
    scroll-behavior: smooth;
}

::-webkit-scrollbar {
    display: none;
}

body {
    font-family: var(--tertiary-font);
    background: var(--color-bg);
    color: var(--color-white);
    line-height: 1.7;
    /* background: #22314A,      */
    background:  #121212 fixed repeat;
    background-size: cover;  
}

/* ==== General Styles ==== */

.container {
    width: var(--container-width-lg);
    margin: 0 auto;
}

h2, h3, h4, h5{
    font-weight: 500;
}

h1 {
    font-weight: 700;
    font-family: var(--primary-font);
    font-size: 2.5rem;
}

h3 {
    font-family: var(--secondary-font);
}


section {
    margin-top: 5rem;
    height: 100vh;
    display: flex;
    flex-direction: column;
    height: 100vh;
    padding-top: 2rem;
    display: flex;
    border-radius: 1.5rem;
    padding-left: 1.4rem;
    padding-right: 2rem;
    padding-bottom: 3rem;
}


section > h2, section > h5 {
    text-align: center;
    color: var(--color-light);
}

section > h2 {
    color: var(--color-primary);
    margin-bottom: 3rem;
}

.text-light {
    color: var(--color-light);
}

a {
    color: var(--color-primary);
    transition: var(--transition);
}

a:hover {
    color: var(--color-white);
}

.btn {
    width: max-content;
    display: inline-block;
    color: var(--color-primary);
    padding: 0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    transition: var(--transition);
}

.btn:hover {
    background: var(--color-white);
    color: var(--color-bg);
    border-color: transparent;
}

.btn-primary {
    background: var(--color-primary);
    color: var(--color-bg);
}

.btn-secondary {
    background: var(--color-bg-variant);
    color:var(--color-primary);
}
