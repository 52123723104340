.about__h2 {
    padding: 0;
    margin: 0 auto;
    color: white;
}

 .about_me {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: row;
    background-color: var(--container-bg);
    max-width: 100%;
    padding: 1.5rem;
    margin: 0 auto;
 }

 .about_section {
    display: flex;
    flex-direction: column;
    color: var(--color-primary);
    padding: 100px 50px;
    max-width: 50%;
 }

.about_image {
    object-fit: fill;
    width: 30%;
    height: auto;
    padding: 0;
    margin: 2.5rem;
    border-radius: 10px;
    box-shadow: 9px 9px 2px 10px rgba(77, 181, 255, 0.66),-9px -9px 2px 10px rgba(50, 15, 116, 0.66);
}

.about_btn {
    margin: 10px 20px;
    margin-top: 20px;
}
 
 .skills {
    color: var(color-tertiary);
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-size: 3rem;
    margin: 2.5rem;
    padding: 20px;
    align-items: center;
    background-color: var(--color-bg);
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 4px );
    -webkit-backdrop-filter: blur( 4px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    
 }

 .toolbox_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: .8rem;
    width: 100%;
    margin: 0 auto;
    padding: 20px;

    
 }

 .tool_icons {
    display: grid;
    grid-row-gap: 20px;
    grid-column-gap: 9px;
    font-size: 2.5rem;
     
    
 }
 