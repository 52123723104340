.projects_container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(1fr);
    grid-gap: 2.5rem;
    align-items: center;
    background-color: var(--container-bg);
    width: 100%;
    padding: 1.5rem;
}

.portfolio_item {
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.portfolio_item:hover {
    border-color: var(--color-primary-variant);
    background: transparent;
}

.portfolio_item_image {
    border-radius: 1.5rem;
     
}

.portfolio_item h3 {
    margin: 1.2rem 0 2rem;
}

.portfolio_item_ct {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}
